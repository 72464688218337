<template>
    <div class="home-new-main-page">
        <Intersection
            @onIntersection="onIntersectionProductNewest()"
        >
            <ProductNewestSkeletons v-if="state.products.length === 0" />

            <section v-else class="page-section home-new-main-page-section" v-bind:style="{ background: 'linear-gradient(180deg, rgba(16, 16, 16, 0.6) 0%, rgba(16, 16, 16, 0.6) 100%),url(' + state.banner_products + ') no-repeat center center',backgroundSize: 'cover'}">
                <div class="section-title-wrapper">
                    <h3 class="search_title">Sản phẩm mới</h3>
                    <div class="view-all">
                        <a class="primary-button" href="/new-arrival/?order_by=timestamp&itm_source=homepage&itm_medium=menu&itm_campaign=normal&itm_content=newproduct" title="Sản phẩm mới ở dosiin">
                            <span class="primary-link_text">Xem thêm</span>
                            <img src="https://dosi-in.com/images/assets/icons/seemore-home-new.png" width="20" height="20">
                        </a>
                    </div>
                </div>
                <div class="section-content-wrapper">
                    <div class="home-new-title">
                        <img class="icon-title" src="https://dosi-in.com/images/assets/icons/icon-store.png" alt="union" width="16" height="16"><span>
                        <span class='dosiin-font-bold'>  </span> Sản phẩm mới cập nhật</span>
                    </div>
                    <ul class="home-new-img-brand">
                        <template v-for="(product , index) in state.products" :key="index">
                            <li :class="index === 6 ? 'last-img-brand' : '' " >
                                <img 
                                    class="user-avatar img-square pict"
                                    v-lazy="_productThumbnailSticker(product.main_pair , product.sticker.main_pair)" 
                                    :alt="product.product" 
                                    :title="product.product"
                                    width="40" 
                                    height="40"
                                />
                                <a v-if="index === 6" class="more-img-text text-square" href="" title="more">+ 99</a>
                            </li>
                        </template>
                    </ul>
                </div>
            </section>
        </Intersection>

        <Intersection
            @onIntersection="onIntersectionBrandNewest()"
        >
            <BrandNewestSkeletons v-if="state.brands.length === 0" />

            <section v-else class="page-section home-new-main-page-section section-new-brand" v-bind:style="{ background: 'linear-gradient(180deg, rgba(16, 16, 16, 0.6) 0%, rgba(16, 16, 16, 0.6) 100%),url(' + state.banner_brands + ') no-repeat center center',backgroundSize: 'cover'}">
                <div class="section-title-wrapper">
                    <h3 class="search_title">Thương hiệu mới</h3>
                    <div class="view-all">
                        <a class="primary-button" href="/brands-newarrival/?order_by=timestamp&itm_source=homepage&itm_medium=menu&itm_campaign=normal&itm_content=newproduct" title="Thương hiệu mới ở dosiin">
                            <span class="primary-link_text">Xem thêm</span>
                            <img src="https://dosi-in.com/images/assets/icons/seemore-home-new.png" width="20" height="20">
                        </a>
                    </div>
                </div>
                <div class="section-content-wrapper">
                    <div class="home-new-title">
                        <img class="icon-title" src="https://dosi-in.com/images/assets/icons/icon-brand.png" alt="union" width="16" height="16">            
                        <span>
                            <span class="dosiin-font-boldd">  </span> Thương hiệu mới
                        </span>
                    </div>
                    <ul class="home-new-img-brand">
                        <template v-for="(brand , index) in state.brands" :key="index">
                            <li :class="index === 6 ? 'last-img-brand group-img-circle' : 'group-img-circle' " >
                                <img 
                                    class="user-avatar img-circle"
                                    v-lazy="_companyAvatar(brand.logos.theme)" 
                                    :alt="brand.brand" 
                                    :title="brand.brand"
                                    width="40" 
                                    height="40"
                                />
                                <a v-if="index === 6" class="more-img-text text-circle" href="" title="more">+ 99</a>
                            </li>
                        </template>
                    </ul>
                </div>
            </section>
        </Intersection>

    </div>
</template>

<script>
    import ProductHorizon from '@/components/product/ProductItemHorizon';
    import ProductNewestSkeletons from '@/components/skeletons/sections/ProductNewest';
    import BrandNewestSkeletons from '@/components/skeletons/sections/BrandsNewest';
    import BrandItemVertical from '@/components/brand/BrandItemVertical';
    import { inject} from '@vue/runtime-core';
    export default {
        components:{
            ProductHorizon,
            BrandItemVertical,
            ProductNewestSkeletons,
            BrandNewestSkeletons
        },
        setup() {
            const state = inject('state');
            const onIntersectionProductNewest = inject('onIntersectionProductNewest');
            const onIntersectionBrandNewest = inject('onIntersectionBrandNewest');

            return {
                state,
                onIntersectionProductNewest,
                onIntersectionBrandNewest
            }
        },
    }
</script>