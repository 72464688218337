<template>
    <section class="page-section home-new-main-page-section" v-if="layout == 4">
        <div class="section-title-wrapper">
            <Skeletor width="20%" />
            <Skeletor width="15%" />
        </div>
        <div class="section-content-wrapper">
            <div class="home-new-title">
                <Skeletor width="30%" />
            </div>
            <ul class="home-new-img-brand">
                <template v-for="i in number" :key="i">
                    <li class='group-img-circle' >
                        <Skeletor class="user-avatar img-circle" width="40" height="40" pill />
                    </li>
                </template>
            </ul>
        </div>
    </section>

    <div v-else class="home-new-main-page-section home_new-inner dosiin_mb-16 section-new-brand">
        <div class="home_new-inner_overlay">
            <!-- <img :src="state.banner_products" alt=""> -->
        </div>
        <div class="section-title-wrapper section_position-ttl">
            <Skeletor width="120" />
        </div>
        <div class="section-content-wrapper section_position-content">
            <div class="home-new-title dosiin_mb-16">
                <Skeletor width="230" />
            </div>
            <ul class="home-new-img-brand">
                <template v-for="i in number" :key="i">
                    <li class="group-img-circle" v-if="i <= 5">
                        <Skeletor class="user-avatar img-circle" width="140" height="140" pill />
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
    import { toRefs } from '@vue/reactivity';

    export default {
        props:{
            number : {
                type : Number,
                default : 7
            },
            gridClass:{
                type : String,
                default : 'grid-11'
            }
        },
        setup(props){


            return {
                ...toRefs(props)
            }
        }
    }
</script>

<style scoped>
    .home_new-inner,
    .home_new-inner_overlay,
    .home_new-inner_overlay::before {
        height: 560px;
    }
    
    .home_new-inner .user-avatar {
        border: unset;      
    }
</style>