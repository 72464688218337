<template>
  <NewestMB v-if="layout == 4" />
  <NewestPC v-else />
</template>

<script>
import NewestMB from "@/pages/mb/home/Newest";
import NewestPC from "@/pages/pc/home/Newest";
import { provide, reactive } from "vue";
import { serialize } from "@/utlis/serialize";
import BrandService from "@/services/brand";
import ProductService from "@/services/product";
import BlockService from "@/services/block";

export default {
  name: "newest",
  components: {
    NewestMB,
    NewestPC,
  },
  setup() {
    const state = reactive({
      products: [],
      brands: [],
      banner_products: [],
      banner_brands: [],
    });

    async function onIntersectionProductNewest() {
      var params = {
        sort_by: "timestamp",
        sort_order: "desc",
        limit: "7",
      };

      const response = await ProductService.fetchProducts(params);

      if (response.data) {
        state.products = Object.values(response.data.products);
      }
    }

    async function onIntersectionBrandNewest() {
      var params = {
        sort_by: "date",
        sort_order: "desc",
        get_banner: true,
        get_follow: true,
        limit: "7",
      };
      const response = await BrandService.fetchBrands(serialize(params));

      if (response.data) {
        state.brands = Object.values(response.data.brands);

        $.each(response.data.brands, function (key, value) {
          if (value.banner_shop != "") {
            let banner_brands = "";
            banner_brands =
              value.banner_shop[Object.keys(value.banner_shop)[0]].detailed.relative_path;
            state.banner_brands = process.env.VUE_APP_CDN_URL + banner_brands;
            return false;
          }
        });
      }
    }

    getBanners(631);

    async function getBanners(id) {
      const response = await BlockService.fetchBlocks(id);

      if (response.data) {
        state.banner_products = response.data[0];
      }
    }

    provide("state", state);
    provide("onIntersectionProductNewest", onIntersectionProductNewest);
    provide("onIntersectionBrandNewest", onIntersectionBrandNewest);
  },
};
</script>
