<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <section class="dosiin_home-new-main-page page-section">
      <Intersection @onIntersection="onIntersectionProductNewest()">
        <ProductNewestSkeletons v-if="state.products.length === 0" />

        <div
          v-else
          class="home-new-main-page-section home_new-inner dosiin_mb-16 section-new-product"
        >
          <router-link
            class="home_new-inner_overlay dosiin_d-block"
            :to="{
              name: 'new-arrival',
              query: {
                order_by: 'timestamp',
                itm_source: 'homepage',
                itm_medium: menu,
                itm_campaign: 'normal',
                itm_content: 'newproduct',
              },
            }"
            title="Xem thêm sản phẩm"
          >
            <img
              v-lazy="{
                src: _bannerImage(state.banner_products.main_pair),
                loading: _bannerDefault,
                error: _bannerDefault,
              }"
            />
          </router-link>
          <div class="section-title-wrapper section_position-ttl">
            <h3 class="search_title">Sản phẩm mới</h3>
          </div>
          <div class="section-content-wrapper section_position-content">
            <div class="home-new-title dosiin_mb-16">
              <img
                class="icon-title"
                src="https://dosi-in.com/images/assets/icons/icon-store.png"
                alt="union"
                width="24"
                height="24"
              />
              <span>
                <span class="dosiin-font-bold dosiin_margin-4"> 99+ </span> sản phẩm mới
                cập nhật
              </span>
            </div>
            <ul class="home-new-img-brand">
              <template v-for="(product, index) in state.products" :key="index">
                <li v-if="index <= 3">
                  <router-link :to="_productUrl(product)" :title="product.product">
                    <img
                      class="user-avatar img-square"
                      v-lazy="
                        _productThumbnailSticker(
                          product.main_pair,
                          product.sticker.main_pair
                        )
                      "
                      :alt="product.product"
                      :title="product.product"
                      width="138"
                      height="138"
                    />
                  </router-link>
                </li>
              </template>
              <li class="last-img-brand">
                <router-link
                  class="more-img-text text-square d-flex-container dosiin_h-100"
                  :to="{
                    name: 'new-arrival',
                    query: {
                      order_by: 'timestamp',
                      itm_source: 'homepage',
                      itm_medium: 'menu',
                      itm_campaign: 'normal',
                      itm_content: 'newproduct',
                    },
                  }"
                  title="Xem thêm sản phẩm"
                >
                  <i class="dsi dsi-arrow-forward"></i>
                  <span>Xem sản phẩm</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </Intersection>

      <Intersection @onIntersection="onIntersectionBrandNewest()">
        <BrandNewestSkeletons v-if="state.brands.length === 0" />

        <div
          v-else
          class="home-new-main-page-section home_new-inner dosiin_mb-16 section-new-brand"
        >
          <router-link
            class="home_new-inner_overlay dosiin_d-block"
            :to="{ name: 'brands-newarrival' }"
            title="Xem thêm thương hiệu"
          >
            <img :src="state.banner_brands" alt="" />
          </router-link>
          <div class="section-title-wrapper section_position-ttl">
            <h3 class="search_title">Thương hiệu mới</h3>
          </div>
          <div class="section-content-wrapper section_position-content">
            <div class="home-new-title dosiin_mb-16">
              <img
                class="icon-title"
                src="https://dosi-in.com/images/assets/icons/icon-brand.png"
                alt="union"
                width="24"
                height="24"
              />
              <span>
                <span
                  class="dosiin-font-boldd dosiin_margin-4"
                  v-text="state.brands.length"
                >
                </span>
                thương hiệu mới
              </span>
            </div>
            <ul class="home-new-img-brand">
              <template v-for="(brand, index) in state.brands" :key="index">
                <li class="group-img-circle" v-if="index <= 3">
                  <router-link :to="_brandUrl(brand.seo_name)" :title="brand.brand">
                    <img
                      class="user-avatar img-circle"
                      v-lazy="_companyAvatarNewest(brand.logos.theme)"
                      :alt="brand.brand"
                      :title="brand.brand"
                      width="138"
                      height="138"
                    />
                  </router-link>
                </li>
              </template>

              <li class="last-img-brand group-img-circle">
                <router-link
                  class="more-img-text text-circle d-flex-container dosiin_h-100"
                  :to="{ name: 'brands-newarrival' }"
                  title="Xem thêm thương hiệu"
                >
                  <i class="dsi dsi-arrow-forward"></i>
                  <span>Xem thương hiệu</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </Intersection>
    </section>
  </div>
</template>

<script>
import ProductHorizon from "@/components/product/ProductItemHorizon";
import ProductNewestSkeletons from "@/components/skeletons/sections/ProductNewest";
import BrandNewestSkeletons from "@/components/skeletons/sections/BrandsNewest";
import BrandItemVertical from "@/components/brand/BrandItemVertical";
import { inject } from "@vue/runtime-core";
export default {
  components: {
    ProductHorizon,
    BrandItemVertical,
    ProductNewestSkeletons,
    BrandNewestSkeletons,
  },
  setup() {
    const state = inject("state");
    const onIntersectionProductNewest = inject("onIntersectionProductNewest");
    const onIntersectionBrandNewest = inject("onIntersectionBrandNewest");

    return {
      state,
      onIntersectionProductNewest,
      onIntersectionBrandNewest,
    };
  },
};
</script>

<style scoped>
.home_new-inner,
.home_new-inner_overlay {
  max-height: 560px;
}

.section_position-content .home-new-img-brand .last-img-brand a {
  flex-direction: column;
}
</style>
